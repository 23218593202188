#rentalEdit {
    .editable-component {
        display: flex;
        margin-bottom: 5px;
    
        &-inline {
            display: inline-flex;
        }

        &-block {
            display: block;
        }

        .editable-image {
            max-height: 360px;
            width: auto;
        }
    
        .editable-item {
            margin-bottom: 0 !important;
        }
     
        .button-container {
            align-self: center;
        }
     
         .btn.btn-editable {
            margin-left: 5px;
            color: white;
            font-size: 12px;
        }
    } 
}
