.page-title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}

.card-list {
  .bwm-card {
    border: none;

    .card-body {
      padding: 8px 2px;

      .card-subtitle {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
      }

      .card-title {
        font-size: 19px;
        margin: 3px 0;
      }

      .card-text {
        font-size: 14px;
        font-weight: 300;
        color: #7e7e7e;
        margin-bottom: 0;
      }

      .type-{
        &apartment {
          color: #a72da3
        }

        &house {
          color: #bf1818
        }

        &condo {
          color: #d68000
        }

        &townhouse {
          color: green
        }
      }
    }
  }
}  