@import './colors.scss';
@import './header.scss';
@import './rental-home';
@import './rental-detail';
@import './forms';
@import './booking-reserve';
@import './editable';

html, body {
    height: 100%;
}

.rental-link {
    color: inherit;

    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

.pvc-container {
    padding-top: 70px;
}


.navbar-dark .navbar-nav .nav-link {
    &:hover {
        color: $main-blue-color;
    }
}

.btn {

    &.btn-pvc-main {
        border-color: $main-blue-color;
        color: $main-blue-color;

        &:hover, &:active, &:focus {
            background-color: $main-blue-color;
            border-color: $main-blue-color;
            color: white;
            box-shadow: none;
        }

        &:disabled {
            cursor: no-drop 
        }
    }
}