.img-upload-container {
    .fileInput {
        display: none;
    }
 
    .img-upload {
        margin-bottom: 0;
    }
 
    .upload-text {
        font-size: 17px;
    }

    .img-preview-container {
        background-color: #f7f7f7;
        padding: 10px;
        overflow: scroll;
   
        .img-preview {
            height: 116px;
   
            img {
                height: 100%;
            }
        }

        .upload-status {
            margin-bottom: 0;
            margin-top: 10px;
        }

        .spinner-container {
            padding: 10px;
        }
    }
} 